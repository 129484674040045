import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO title="Uses" />
    <div>
      <h1>Uses</h1>
      <p>Listed below are some of the languages, tools and technologies I use.</p>
      <h4>Languages</h4>
      <ul>
        <li><b>JavaScript (React, Node, Gatsby)</b> - Intermediate</li>
        <li><b>Python</b> - Intermediate</li>
        <li><b>ABAP</b> - Expert</li>
        <li><b>SQL (MySQL, Postgres)</b> - Intermediate</li>
        <li><b>C++</b> - Beginner</li>
      </ul>
      <h4>Products + Tools</h4>
      <ul>
        <li><b>Amazon Web Services</b> - Intermediate</li>
        <li><b>Mulesoft</b> - Intermediate</li>
        <li><b>SAP Cloud Platform</b> - Beginner</li>
        <li><b>Wordpress</b> - Beginner</li>
      </ul>
    </div>
  </Layout>
)